import { lazy } from 'react';
import { Route, BrowserRouter, Routes as ReactRoutes } from 'react-router-dom';

const Home = lazy(() => import('./Containers/Home'));

export default function Routes() {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/tr/:token" element={<Home />} />

        <Route path="/d/:token" element={<Home isDemo />} />

        <Route path="*" element={<Home />} />
      </ReactRoutes>
    </BrowserRouter>
  );
}
