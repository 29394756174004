import React from 'react';
import './App.css';
import Routes from './Routes';
import './i18n';

function App() {
  return (
    <React.Suspense fallback="loading">
      <div className="bg-primary-background min-h-screen flex">
        <Routes />
      </div>
    </React.Suspense>
  );
}

export default App;
